<template>
    <div
        v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'admin-operation', 'admin-bd', 'superadmin-tech', 'superadmin-product', 'user-management'])" 
        class="uk-container uk-container-expand uk-margin-top card-scrollable"
    >
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else>
            <div class="uk-flex uk-flex-between uk-flex-middle">
                <p class="uk-text-large">Job Vacancy Details</p>
                <router-link to="/admin/marketing/silver_package" uk-close></router-link>
            </div>

            <div v-if="detailPackage !== null" class="uk-margin-remove uk-padding-remove">
                <p class="uk-text-bold uk-text-nowrap uk-margin-remove uk-padding-remove">{{ detailPackage.title || '-' }}</p>
                <p class="uk-text-default uk-padding-remove uk-margin-small-top uk-margin-remove-bottom">{{ detailPackage.company.name || '-' }}</p>
            </div>

            <div v-if="detailPackage !== null" class="uk-flex uk-flex-middle uk-flex-between uk-padding-remove uk-margin-small-top" style="gap: 20px;">
                <div class="uk-flex uk-flex-middle" style="gap: 5px;">
                    <img :src="`${images}/calendar.svg`" alt="Calendar icon">
                    <p class="uk-text-nowrap uk-text-small uk-text-bold">{{ dateFormat(detailPackage.job_start) }} - {{ dateFormat(detailPackage.job_end) }}</p>
                </div>
                <div class="uk-flex uk-flex-middle" style="gap: 5px;">
                    <img :src="`${images}/file.svg`" alt="File icon">
                    <p class="uk-text-nowrap uk-text-small uk-text-bold">{{ detailPackage.job_type.name || '-' }}</p>
                </div>
                <div class="uk-flex uk-flex-middle" style="gap: 5px;">
                    <img :src="`${images}/send-email.svg`" alt="Send email icon">
                    <p class="uk-text-nowrap uk-text-small uk-text-bold">{{ detailPackage.city || '-' }}</p>
                </div>
                <div class="uk-flex uk-flex-middle" style="gap: 5px;">
                    <img :src="`${images}/location.svg`" alt="Location icon">
                    <p class="uk-text-nowrap uk-text-small uk-text-bold">{{ salaryFormat(detailPackage) || '-' }}</p>
                </div>
                <div class="uk-flex uk-flex-middle" style="gap: 5px;">
                    <img :src="`${images}/money.svg`" alt="Money icon">
                    <p class="uk-text-nowrap uk-text-small uk-text-bold">{{ detailPackage.company.email || '-' }}</p>
                </div>
            </div>

            <div class="uk-card uk-card-default uk-margin-top">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                        <thead>
                            <tr>
                                <th class="uk-text-nowrap">Name</th>
                                <th class="uk-text-nowrap">Location</th>
                                <th class="uk-text-nowrap">Phone Number</th>
                                <th class="uk-text-nowrap">Education</th>
                            </tr>
                        </thead>
                        <loading-table v-if="tableLoading" :colspan="5"/>
                        <template v-else>
                            <tbody v-if="jobApplicantList !== null && jobApplicantList.docs.length > 0">
                                <tr v-for="(item, index) in jobApplicantList.docs" :key="index">
                                    <td class="uk-text-nowrap">
                                        <img
                                            :data-src="`${images}/avatar.svg`"
                                            alt="Avatar Icon"
                                            class="uk-preserve-width uk-border-circle"
                                            style="object-fit: contain; object-position: center; width: 40px; height: 40px;"
                                            uk-img
                                        >
                                        <span class="uk-text-bold uk-margin-small-left">{{ item.fullname || '-' }}</span>
                                    </td>
                                    <td class="uk-text-nowrap">{{ item.location || '-' }}</td>
                                    <td class="uk-text-nowrap">{{ item.phone_number || '-' }}</td>
                                    <td class="uk-text-nowrap">{{ item.education || '-' }}</td>
                                    <td class="uk-text-nowrap">
                                        <router-link :to="`/admin/marketing/silver_package/detail/${$route.params.id}/detail_job_applicant/${item._id}`" href="javascript:void(0);">
                                            <img class="uk-preserve-width uk-border-circle uk-margin-small-right" :src="`${images}/visibility.svg`" width="20" alt="">
                                        </router-link>
                                        <a href="javascript:void(0);" @click="downloadCV(item.user_id)">
                                            <img class="uk-preserve-width uk-border-circle uk-margin-small-right" :src="`${images}/cloud_download.svg`" width="20" alt="">
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <empty-table v-else :colspan="5"/>
                        </template>
                    </table>
                </div>
                <pagination
                    v-if="jobApplicantList !== null"
                    :total-data="jobApplicantList.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from '@/utils/constant';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import {
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    name: 'DetailSilverPackage',
    components: {
        Pagination,
        EmptyTable,
        LoadingTable
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            detailPackage: null,
            jobApplicantList: null,
            meta: {
                limit: 50,
                page: 1,
                job_id: null
            },
            tableLoading: false
        };
    },
    computed: {
        user_cred() {
            return getUserLogin();
        }
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.detailPackage = await this.showSilverPackage(this.$route.params.id);
            this.isLoading = false;

            this.tableLoading = true;
            this.meta.job_id = this.$route.params.id;
            this.jobApplicantList = await this.jobApplicantListSilverPackage(this.meta);
            this.tableLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.tableLoading = false;
        }
    },
    methods: {
        ...mapActions({
            showSilverPackage: 'job/showSilverPackage',
            jobApplicantListSilverPackage: 'job/jobApplicantListSilverPackage',
            downloadCvSilverPackage: 'job/downloadCvSilverPackage'
        }),
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        salaryFormat(data) {
            if(data.salary !== null) {
                return formatter.salaryCurrency(data.salary);
            } else if(data.salary_min !== null && data.salary_max !== null) {
                return `${formatter.salaryCurrency(data.salary_min)} - ${formatter.salaryCurrency(data.salary_max)}`;
            }
        },
        dateFormat(data) {
            return formatter.dateComplete(data);
        },
        async downloadCV(user_id) {
            try {
                const result = await this.downloadCvSilverPackage(user_id);
                window.open(result.cv_path.file_url, '_blank');
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>
